import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import './service-inner.css'
import PageHero from '../../components/pageHero'
import servicesHero from '../../images/services-hero.png'

const EducationPage = () => (
  <Layout>
    <SEO title="Page two" />
    <PageHero {...{title: "Workplace Culture, Harassment & Safety", image: servicesHero}}/>
    <div className="sp-content-wrapper flex">
      <div className="sp-content">
      <h1 className="title">Creating safe and inclusive environments</h1>
      <p>Similar to every other industry, hospitality is not immune from workplace harassment or safety challenges.  The Libation Team works with establishments to create safe and inclusive environments for their staff and just as importantly - safe and welcoming environments for the patrons.</p>
      <p>Our team has a unique understanding of legislative obligations and political trends, as well as extensive diversity, inclusion and violence prevention experience.  We have worked on transformative projects in diverse regions and sectors around the world. </p>
      <p>Understanding the risks and opportunities that exist within the hospitality industry, we have the experience to guide you through a comprehensive policy generation process that outline a clear roadmap for operations and provide decision making guidance for employees.  Our policies and procedures don’t just provide formalized accountability they help to build the framework of your internal culture. </p>
      <p>We help organizations step beyond traditional health and safety and do more to develop safe, inclusive and successful workplaces.</p>
      <div className="service-inner-link-anchor">
      <a href="/case-studies/">View Case Studies</a>
      </div>
      </div>
    </div>
  </Layout>
)

export default EducationPage
